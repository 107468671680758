import React from "react";
import {
  Hero,
  Bids,
  Top_collection,
  Tranding_category,
  NewseLatter,
} from "../../components/component";
import Meta from "../../components/Meta";
import Link from 'next/link';

const Home_1 = () => {
  return (
    <main>
      <Meta title={null} />
      <div id="homestart" />
      <Hero />
      {/* <Bids /> */}

      <div className="pt-24" id="numbers-speak">
        <h2 className="font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white">
          
        </h2>

        <div className="grid grid-cols-2 md:grid-cols-4">
          <div className="mb-10 text-center">
            <span className="font-display text-jacarta-700 block text-5xl dark:text-white">
              2023
            </span>
            <span className="dark:text-jacarta-300 block">Founded</span>
          </div>
          <div className="mb-10 text-center">
            <span className="font-display text-jacarta-700 block text-5xl dark:text-white">
              $-
            </span>
            <span className="dark:text-jacarta-300 block">
              Trading volume
            </span>
          </div>
          <div className="mb-10 text-center">
            <span className="font-display text-jacarta-700 block text-5xl dark:text-white">
              -
            </span>
            <span className="dark:text-jacarta-300 block">
              Holders
            </span>
          </div>
          <div className="mb-10 text-center">
            <span className="font-display text-jacarta-700 block text-5xl dark:text-white">
              690T
            </span>
            <span className="dark:text-jacarta-300 block">Token Supply</span>
          </div>
        </div>
      </div>

      <Top_collection />
      {/* <Tranding_category /> */}

      

          <div className="container">
            <div className="lg:flex lg:justify-between">
              {/* <!-- Image --> */}
              <div className="lg:w-[55%]">
                <div className="relative">
                  <img
                    src="/images/about/story.png"
                    width="200"
                    height="200"
                    className="mx-auto mt-8 w-[80%] rotate-[8deg] rounded-[10rem]"
                  />
                  <img
                    src="/images/hero/3D_elements.png"
                    alt=""
                    className="animate-fly absolute top-0"
                  />
                </div>
              </div>

              {/* <!-- Info --> */}
              <div className="py-20 mb-20 lg:w-[45%] lg:pl-16">
                <h2 className="text-jacarta-700 font-display mb-6 text-2xl dark:text-white">
                  About $OTAK
                </h2>
                <p className="dark:text-jacarta-300 mb-8 text-lg leading-normal">
                Otaku Inu: The meme coin for anime lovers! Join the revolution, embrace and dive into a world
                where cryptocurrency meets otaku culture. Invest now and be part of the meme coin phenomenon!
                </p>
                <p className="dark:text-jacarta-300 mb-10">
                Become part of a vibrant community that celebrates anime, meme culture,
                and the limitless possibilities of cryptocurrency. Whether you are a seasoned investor or a casual fan, Otaku Inu
                welcomes you with open arms, ready to embark on a journey that blends the best of meme, anime,  and blockchain technology.
                </p>
              </div>
            </div>
        <br id="roadmap" />
          </div>



      <NewseLatter />

      <section className="pt-32 pb-28 dark:bg-jacarta-900" id="tokenomics">
				<div className="container">
					<div className="lg:flex lg:items-center lg:justify-between">
						<div className="lg:w-1/2 lg:pr-16">
							<h2 className="mb-6 font-display text-3xl text-jacarta-700 dark:text-white">
								Tokenomics
							</h2>
							<p className="mb-8 dark:text-jacarta-300">
              Otaku Inu $OTAK boasts a 0% buy and sell tax, eliminating fees and charges during transactions.
              Unrestricted trading, empowering users to freely participate in the market without extra costs.
							</p>
							<p className="mb-8 dark:text-jacarta-300">
              In terms of token allocation, a significant portion of 88.9% is dedicated to the liquidity pool,
              ensuring a healthy supply and supporting price stability. Additionally, 11.1% of the tokens are
              allocated to a multi-signature wallet, with 6.9% reserved for securing listings on centralized
              exchanges (CEX) and 4.20% for marketing initiatives, development support, and infrastructure
              enhancements. These allocations demonstrate a commitment to expanding the {"coin's"} visibility,
              driving adoption, and ensuring ongoing progress for the project. By striking a balance between
              liquidity, marketing, and development efforts, Otaku Inu aims to create a sustainable ecosystem
              that can thrive in the ever-evolving cryptocurrency market.
							</p>
              <div className="flex flex-row">
                <Link href="https://etherscan.io/address/0x31552De5A82f69150411322646DB38DC133A9Cfc">
                  <a
                    className={"mr-5 dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"}
                  >
                    Multi-Sig Wallet
                  </a>
                </Link>
                
                <Link href="https://etherscan.io/address/0xc84b6c2545ba917bf12964d64b4811f9190eff9e">
                  <a
                    className={"mr-5 dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"}
                  >
                    Deployer
                  </a>
                </Link>
                
                <Link href="https://app.ens.domains/otakuinu.eth">
                  <a
                    className={"mr-5 dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"}
                  >
                    ENS: otakuinu.eth
                  </a>
                </Link>
              </div>
						</div>
						<div className="lg:w-[45%]">
							<div className="md:flex md:space-x-6 xl:space-x-12">
								<div className="md:w-1/2">
									<div className="mb-6 rounded-2.5xl border border-jacarta-100 p-12 dark:border-jacarta-600 dark:bg-jacarta-700 xl:mb-12">
										<span className="font-display text-4xl font-semibold text-green md:text-6xl">
											690T
										</span>
										<p className="text-md text-jacarta-700 dark:text-white">
										  Token Supply
										</p>
									</div>
									<div className="mb-6 rounded-2.5xl border border-jacarta-100 p-12 dark:border-jacarta-600 dark:bg-jacarta-700 xl:mb-12">
										<span className="font-display text-4xl font-semibold text-green md:text-6xl">
											11.1%
										</span>
										<p className="text-md text-jacarta-700 dark:text-white">Multi-Sig Wallet</p>
									</div>
								</div>
								<div className="mt-6 md:w-1/2">
									<div className="mb-6 rounded-2.5xl border border-jacarta-100 p-12 dark:border-jacarta-600 dark:bg-jacarta-700 xl:mb-12">
										<span className="font-display text-4xl font-semibold text-green md:text-6xl">
											88.9%
										</span>
										<p className="text-md text-jacarta-700 dark:text-white">Liquidity Pool</p>
									</div>
									<div className="mb-6 rounded-2.5xl border border-jacarta-100 p-12 dark:border-jacarta-600 dark:bg-jacarta-700 xl:mb-12">
										<span className="font-display text-4xl font-semibold text-green md:text-6xl">
											0%
										</span>
										<p className="text-md text-jacarta-700 dark:text-white">
                      Buy/Sell Tax
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

      <section className="dark:bg-jacarta-800 relative py-24">

      <div className="container">
      <p className="text-jacarta-700 mx-auto max-w-2xl text-center text-lg dark:text-white">
          Join our mailing list to stay in the loop with our progress
        </p>

        <div className="mx-auto mt-7 max-w-md text-center">
          <form className="relative" onSubmit={(e) => e.preventDefault()}>
            <input
              type="email"
              placeholder="Email address"
              className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
            />
            <button className="hover:bg-accent-dark font-display bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white">
              Subscribe
            </button>
          </form>
        </div>
        </div>
    </section>
      
    </main>
  );
};

export default Home_1;
